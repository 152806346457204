<template>
	<div>
		<router-view/>
		<van-tabbar class="tabber_color" style="height: 60px;"  :style="{'top':(docmHeight - 60) + 'px'}" v-model="active">
			<van-tabbar-item to="/home">
				<span>试驾</span>
				<template #icon="props">
					<img :src="props.active ? icon.active : icon.inactive" />
				</template>
			</van-tabbar-item>
			<van-tabbar-item to="/center">
				<span>我的</span>
				<template #icon="props">
					<img :src="props.active ? icon2.active : icon2.inactive" />
				</template>
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
export default {
	data() {
		return {
			active: 0,
			icon: {
				active: 'http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_home_checked.png',
				inactive: 'http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_home.png',
			},
			icon2: {
				active: 'http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_center_checked.png',
				inactive: 'http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_center.png',
			},
		}
	},
	computed: {    
    docmHeight() {
			console.log(this.$store.state.height)
      return this.$store.state.height;
    }
  },
	mounted(){
		if(this.$route.name == 'center'){
			this.active = 1
		}
	}
	}
</script>
<style lang="scss" scoped>
.van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after{
	border-width: 0 0;
}
.tabber_color{
	color:#fff;
	background-color: black;
	position: fixed;
	z-index: 9999;
	height: 100px;
	padding: 10px 0;
	.van-tabbar-item--active {
    color: #fff;
		background-color: rgba(255, 255, 255, 0);
}
}

</style>